<template>
  <div>
    <v-card flat>
      <v-toolbar dense flat color="secondary" dark>
        <v-icon>fal fa-save</v-icon>
        <v-toolbar-title class="pl-2">Audit Log</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="700px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <!--Clear Filter Button -->
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="usersStore.usersValueList"
                    label="Staff Member"
                    placeholder=" "
                    persistent-placeholder
                    item-text="name"
                    item-value="id"
                    v-model="user_id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row v-if="auditSection === 15" dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.auditSectionsClient"
                    label="Section"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_section"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row v-if="auditSection === 40" dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.auditSectionsProvider"
                    label="Section"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_section"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row v-if="auditSection === 52" dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.auditSectionsWaitingList"
                    label="Section"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_section"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row v-if="auditSection === 54" dense>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.auditSectionsTeacher"
                    label="Section"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_section"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!--Submit Button-->
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="auditsStore.audits.data && auditsStore.audits.data.length > 0"
      :showTotal="true"
      :currentPage="auditsStore.audits.current_page"
      :lastPage="auditsStore.audits.last_page"
      :total="auditsStore.audits.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <v-card
        v-if="auditsStore.audits.data && auditsStore.audits.data.length > 0"
        flat
        class="white"
        height="100%"
      >
        <v-card-text>
          <v-timeline align-top dense>
            <v-timeline-item
              v-for="(item, i) in auditsStore.audits.data"
              :color="item.creator.d_color ? item.creator.d_color : 'primary'"
              dense
              medium
              fill-dot
              :key="i"
            >
              <template #icon>
                <span class="white--text">
                  {{ $_firstInitial(item.creator.d_nameFirst)
                  }}{{ $_firstInitial(item.creator.d_nameLast) }}
                </span>
              </template>
              <v-row pt-3>
                <v-col>
                  <span>{{ item.creator.name }}</span
                  ><br />
                  <strong
                    ><span v-if="item.event === 'created'" class="green--text"> Created</span>
                    <span v-if="item.event === 'updated'" class="purple--text"> Updated</span>
                    {{ $_formatTimestamp(item.created_at) }}</strong
                  >
                  <v-expansion-panels flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span
                          ><strong>{{ $_switchAuditSection(item.f_section) }}</strong> ID#{{
                            item.auditable_id
                          }}</span
                        >
                        <template v-slot:actions>
                          <v-icon color="primary">
                            fal fa-chevron-down
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  Field Name
                                </th>
                                <th class="text-left">
                                  New Value
                                </th>
                                <th class="text-left">
                                  Previous Value
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(value, name, ii) in item.new_values">
                                <tr :key="ii">
                                  <td>{{ name }}</td>
                                  <td>{{ value }}</td>
                                  <td>{{ item.old_values ? item.old_values[name] : "" }}</td>
                                </tr>
                              </template>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>

      <!--No Recrods Found Message-->
      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Audit Records Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";

const { mapFields } = createHelpers({
  getterType: "auditsStore/getFilterField",
  mutationType: "auditsStore/updateFilterField"
});

export default {
  name: "Audits",
  components: {
    TitleBar,
    Pagination
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      auditsStore: state => state.auditsStore,
      usersStore: state => state.usersStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields([
      "page",
      "section",
      "fk_providerID",
      "fk_facilityID",
      "fk_householdID",
      "fk_clientID",
      "fk_teacherID",
      "fk_waitingListID",
      "user_id",
      "auditable_id",
      "f_section"
    ])
  },
  props: {
    /*  */
    auditSection: {
      type: Number,
      required: false
    },
    providerID: {
      type: Number,
      required: false
    },
    householdID: {
      type: Number,
      required: false
    },
    clientID: {
      type: Number,
      required: false
    },
    teacherID: {
      type: Number,
      required: false
    },
    waitingListItemID: {
      type: Number,
      required: false
    },
    auditableID: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      filterMenu: false
    };
  },
  methods: {
    async initialize() {
      await this.changeSectionCheck();

      this.fk_providerID = this.providerID;
      this.fk_facilityID = "";
      this.fk_householdID = this.householdID;
      this.fk_clientID = this.clientID;
      this.fk_teacherID = this.teacherID;
      this.fk_waitingListID = this.waitingListItemID;
      this.auditable_id = this.auditableID;
      /* Pull audits list */
      const filters = this.$store.getters["auditsStore/getFilters"];
      await this.$store.dispatch("auditsStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      const specialist = {
        f_counselor: ""
      };
      await this.$store.dispatch("usersStore/valueList", specialist);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.fk_providerID = "";
      this.fk_facilityID = "";
      this.fk_householdID = "";
      this.fk_clientID = "";
      this.fk_teacherID = "";
      this.fk_waitingListID = "";
      this.auditable_id = "";

      this.user_id = "";
      this.f_section = "";
      this.page = 1;
    },

    async changeSectionCheck() {
      await this.loadValueLists();
      if (this.auditSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch("auditsStore/clearAudits");
      }
      this.section = this.auditSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (this.user_id || this.f_section) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
