import { render, staticRenderFns } from "./TeacherAudit.vue?vue&type=template&id=157bd37a&scoped=true&"
import script from "./TeacherAudit.vue?vue&type=script&lang=js&"
export * from "./TeacherAudit.vue?vue&type=script&lang=js&"
import style0 from "./TeacherAudit.vue?vue&type=style&index=0&id=157bd37a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "157bd37a",
  null
  
)

export default component.exports